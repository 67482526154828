import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const arrayEndpointsAuth = ["auth"];
    const token = JSON.parse(localStorage.getItem("erp-token"));
    const splitUrl = request.url.split("/");

    if (
      token &&
      arrayEndpointsAuth.indexOf(splitUrl[splitUrl.length - 1]) < 0
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: "Bearer " + token,
          client_id: environment.CLIENT_ID,
          env: environment.ENV,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          client_id: environment.CLIENT_ID,
          env: environment.ENV,
        },
      });
    }

    return next.handle(request);
  }
}
