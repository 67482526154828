<div class="page-container">
  <main class="content">
    <router-outlet></router-outlet>
  </main>

  <!-- <vex-progress-bar></vex-progress-bar>

  <vex-search></vex-search>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      [disableClose]="isDesktop$ | async"
      [fixedInViewport]="!(isDesktop$ | async)"
      [mode]="
        !(isDesktop$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'
      "
      [opened]="(isDesktop$ | async) && !(isLayoutVertical$ | async)"
      class="sidenav"
    >
      <ng-container *ngTemplateOutlet="sidenavRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav
      #quickpanel
      [fixedInViewport]="!(isDesktop$ | async)"
      class="quickpanel"
      mode="over"
      position="end"
    >
      <ng-container *ngTemplateOutlet="quickpanelRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

      <ng-container *ngTemplateOutlet="footerRef"></ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container> -->
</div>
