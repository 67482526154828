import { OrmService } from "src/app/shared/services/orm.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

@Injectable()
export class ViacepService {
  constructor(private http: HttpClient, private ormService: OrmService) {}

  async getAddressByZipcode(zipcode: string) {
    const httpOptions = {
      headers: new HttpHeaders()
        .append("Content-Type", "application/json")
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "*")
        .append("Access-Control-Allow-Credentials", "true"),
    };
    const superagent = require("superagent");
    const result = await superagent.get(
      `https://viacep.com.br/ws/${zipcode}/json`,
      httpOptions
    );

    return result;
  }
}
